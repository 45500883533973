import React from 'react'
import './intro.css'
import gemini from '../images/gemini.png';
import sign1 from '../images/sign1.png';
import sign2  from '../images/sign2.png';
import cancer  from '../images/cancer.png';
import lio from '../images/lio.png';
import virgo from '../images/virgo.png';
import libra from '../images/libra.png';
import scorpio from '../images/scorpio.png';
import capricorn from '../images/capricorn.png';
import sagittarius from '../images/sagittarius.png';
import quarius from '../images/aquarius.png';
import pisces from '../images/pisces.png';
import { useEffect } from 'react';
export default function Intro() {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
  return (
    <>
    <div className="intro">
        <h1>Introduction to Astrology</h1>
        <p>By <a href="about.html">Satyanarayana naik</a></p>
        <img src="https://cdn.pixabay.com/photo/2018/02/22/18/39/astronomy-3173669_640.jpg" alt=""/>
        <p>
            This short online introduction can help make you familiar with most of the astrological techniques and give you a basic understanding of how it's all done. If you wish to learn more about astrology, we suggest you read some of the standard textbooks mentioned in our<a href="books.html" >books tab</a> or <a href="contact.html">contact page</a> .
                <br/><br/>

                Astrology sees mankind as being not only influenced by hereditary factors and the environment, but also by the state of our solar system at the moment of birth. The planets are regarded as basic life-forces, the tools we live by as well as the basis of our very substance. These planetary forces take on different forms, depending on their zodiacal position and on the way they relate to one another.
                
                Zodiacal Men The aspects formed between the planets describe these relationships, the positions of the planets in relation to the place of birth tell us of their expression in the spheres of life depicted by the astrological houses.
                
                By interpreting the roles of these players (the planets) and their qualities (the elements, signs and houses) and creating a synthesis, astrology is able to present a complete and comprehensive picture of the person and his potential, based on the natal horoscope.
                
        </p>
        <h1>12 ZODIAC SIGNS</h1>
        <p>Each of the 12 astrological signs belongs to a certain element in one of its states. This gives us twelve quite different basic types. These varying qualities provide the “backdrop” to the planetary positions. As each horoscope has different planets in different signs, there can never be a “pure” Aries or a “pure” Gemini. Each horoscope is a highly individual, very complex and usually also very varied combination of parts.
            In order to understand the signs, we must take into account the typical expressions of the elements, as well as the characteristics belonging to the planets associated with the signs.
            </p>
        <img src="https://www.astrology.com/img/banners/zodiac-signs-lg.jpg" alt=""/>
        <div className="outsign">
            <div className="sign">
                <div className="title">
                    <h3>Aries</h3><img src={sign1} alt=""/>
                </div>
                <p>Cardinal fire; ruled by Mars 
                    Willpower, impulsive, initiative, courage, energy, activity.
                    Often rushes headlong into things.
                    </p>
            </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Taurus</h3><img src={sign2} alt=""/>
                </div>
                <p>Fixed earth; ruled by Venus 
                    Sensual, pleasure-seeker, steadfast, strives for security.
                    Sees red when provoked for a long time
                    
                </p>
        </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Gemini</h3><img src={{gemini}} alt=""/>
                </div>
                <p>Mutable air; ruled by Mercury 
                    Mental type, witty, communicative, mobile, takes pleasure in learning. Rarely touches down.                    
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Cancer</h3><img src={cancer} alt=""/>
                </div>
               
                <p>Cardinal water; ruled by the Moon 
                    Emotional type, stubborn, seeks safety and closeness.
                    Very much a family person.                           
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Leo</h3><img src={lio} alt=""/>
                </div>
              
                <p>Fixed fire; ruled by the Sun 
                    Glamour, generosity, organizer, the center of attention. 
                    Likes to take the lion's part.                                            
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Virgo</h3><img src={virgo} alt=""/>
                </div>
                <p>Mutable earth; ruled by Mercury 
                    Precise, differentiates, does what is necessary, utilitarian. 
                    A critical point of view.                                                             
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Libra</h3><img src={libra} alt=""/>
                </div>
                <p>Cardinal air; ruled by Venus 
                    A sense of beauty and proportion, tactful, seeks balance and harmony. Sometimes hovers between the scales.                                                                               
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Scorpio</h3><img src={scorpio} alt=""/>
                </div>
                <p>Fixed water; ruled by Pluto 
                    Corrosive, passionate, piercing, extreme situations. 
                    Frequently quarrels with the spirits he called.                                                                                                   
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Sagittarius</h3><img src={sagittarius} alt=""/>
                </div>
                <p>Mutable  fire; ruled by Jupiter 
                    Free spirit, carefree, love of movement, cheerful. 
                    Wanderlust, often seems to be elsewhere.                                                                                                                       
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Capricorn</h3><img src={capricorn} alt=""/>
                </div>
                
                <p>Cardinal earth; ruled by Saturn 
                    Enduring, has a sense of purpose, proud, ambitious. 
                    Can get stuck in craggy heights.                                                                                                                                           
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Aquarius</h3><img src={quarius} alt=""/>
                </div>
                <p>Fixed air; ruled by Uranus 
                    Communicative, humanitarian, progressive, fraternal. 
                    Universal spirit with occasional astonishing obstinacy.                                                                                                                                                             
                </p>
            </div>
        </div>
        <div className="sign">
            <div className="text">
                <div className="title">
                    <h3>Pisces</h3><img src={pisces} alt=""/>
                </div>
                <p>Mutable water; ruled by Neptune 
                    Sensitive, compassionate, helpful, sociable 
                    .Very adaptable, hard to get a hold on.                                                                                                                                                                               
                </p>
            </div>
        </div>
    </div>
    </div>
    </>
  )
}
