import React from 'react'
import './contact.css'
import { useEffect } from 'react';
export default function Contact() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  return (
    <div class="contact">
        <h1>CONTACT US</h1>
        <h2>Message me on whatsApp</h2>
        <p>For questions or requests for customized readings, please send me a message. I will get back to you soon.</p>
        <div class="a">

            <a href="https://wa.me/918374088201" target="_blank">
                <img src="https://cdn.pixabay.com/photo/2015/08/03/13/58/whatsapp-873316_960_720.png" alt=""/>
                Message me on WhatsApp</a>
                <div class="tele">
                    <a href="https://t.me/+91918374088201" target="_blank">
                        <img src="https://cdn.pixabay.com/photo/2020/10/17/13/21/telegram-5662082__340.png" alt=""/>
                        Message me on Telegram</a>
                </div>
        </div>
    </div>
  )
}
