import React from 'react'
import './articles.css'
import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import apiServices from '../services/apiServices';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './article.css'
export default function Articles() {
    const [data, setData] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const navigate = useNavigate();
    const fetchEvents = async () => {
        try {
            const response = await apiServices.fetchAllEvents()
            // const response = await axios.get('https://swayam-website-d9b3d-default-rtdb.asia-southeast1.firebasedatabase.app/events.json');
            setData(response.data);
        } catch (error) {
        console.error('Error fetching Events:', error);
        }
    };
    useEffect(()=>{
        window.scrollTo(0,0);
        fetchEvents();
      },[]);
      var eventIds;
      if(data)
      {
        eventIds = Object.keys(data);

      }
    
  return (
    <>

    <div className="articles">
    {data && eventIds.map((eventId) => {
        return   <><div className="art" ><h1>{data[eventId].eventtitle}</h1>
                                        <p>By <Link to="/about">Satyanarayana naik</Link>{data[eventId].eventdate}</p>
                                        <img onClick={()=>{
                                            navigate(`/article/${eventId}`);
                                        }} src={data[eventId].eventimageone} alt=""/>
                                        <p className="p">{data[eventId].eventDescription}
                                        <br/>
                                        </p>

                                    </div>
                                    <hr style={{color:"white"}}/>
                                        </>

                                    
                                        
                                })}
        
    </div>
    </>
  )
}
