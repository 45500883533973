import React from 'react'
import './homepage.css';
import sky from '../images/sky.jpg'
import fullMoon from '../images/fullMoon.png'
import scrorpio2 from '../images/scrorpio2.png'
// import earth from '../images/earth.jpg'
import { useEffect } from 'react';
export default function HomePage() {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
  return (
    <>
      
    <section className="container">
    <div className="container1">
            <div className="box" id="box">
                <h1>WITH ME BE, AN ASTROLOGER</h1>
                <h3>Connecting the World with the Otherworldly</h3>
                <div className="a">
                    <a href="https://wa.me/918374088201" target="_blank">CONSULTATION</a>
                </div>
            </div>
            <img src={sky} id="sky" className="sky" alt=""/>
            <img src="https://cdn.pixabay.com/photo/2017/06/08/06/03/british-columbia-2382640_1280.jpg" id="sky2" className="sky" alt=""/>
            
            <img src={fullMoon} id="fullMoon" className="fullMoon" alt=""/>
        </div> 
    <div className="video">
        <h1>VIDEO</h1>
        <h3>Check out this great video</h3>

        <iframe src="https://player.vimeo.com/video/813078696?h=e7b14d9bce" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

    </div>
    </section>
    <div className="summury">
        <div className="box box1">
            <img src="https://cdn.pixabay.com/photo/2013/07/13/09/35/dart-155726__340.png" alt=""/>
            
                <h1>AIM</h1>
                <ul>
                    <li>Teaching</li>
                    <li>Coaching</li>
                    <li>To Make Astrologers</li>
                    <li>Consultancy</li>
                </ul>
           
        </div>
        <div className="box">
            <img src={scrorpio2} alt=""/>
            
                <h1>THEME</h1>
                <ul>
                    <li>Sampradaya (Traditional) Jyotisha</li>
                    <li>All Classical Prashna Paddati</li>
                    <li>Naadi, Numerology, Vastu and Allied subject.</li>
                    <li>Muhurta & Remedies</li>
                </ul>
            
        </div>
        <div className="box">
            <img src="https://cdn.pixabay.com/photo/2017/01/31/14/51/amigos-2024747_960_720.png" alt=""/>
           
                <h1>PRIME</h1>
                <ul>
                    <li>Recorded Lessons</li>
                    <li>Zoom classes</li>
                    <li>Physical Workshops on useful mini astrological topics</li>
                    <li>Short seminars and interviews</li>
                </ul>
            </div>
    </div>
    
    </>
  )
}
