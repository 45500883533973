import React, { useEffect } from 'react'
import './courses.css';
export default function Courses() {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
  return (
    <>
       <div className="cources">
        <div className="ccont">
            <div className="box">
                <h1>COURSES</h1>
                <ul>
                    <li>Recorded Lessons.</li>
                    <li>Virtual Class Rooms.</li>
                    <li>Interactive session.</li>
                </ul>
            </div>
            <div className="img">
                <img src="https://cdn.pixabay.com/photo/2018/08/08/08/27/moon-3591570__340.jpg" alt=""/>
            </div>
        </div>
        <div className="ccont2 p1">
            <div className="img">
                <img src="https://cdn.pixabay.com/photo/2017/08/11/08/57/star-2630050__340.jpg" alt=""/>
            </div>
            <div className="box">
                <h3>FREE COURSES</h3>
                <div className="a free">
                    <a href="https://www.dropbox.com/sh/gvrp0fh458rv8jj/AAASjDhNsyqiFw0mGVs5N1WNa?dl=0" target="_blank">FREE VIDEOS AND DOCUMENTS</a>
                </div>
            </div>
        </div>
        <div className="ccont2 left p2">
            <div className="box">
                <h3>PAID COURSES</h3>
                <div className="a">
                    <a href="https://www.dropbox.com/scl/fo/xtve4a39s7cvpswv4khdo/h?dl=0&oref=e&r=ABrJ-8QQdVACc9lCH9DIj-nQyPknpmZHo53BOT2Dl1lm1bTxgSqSPh6_STBW7vBEK6Od3ZeRbUt5F3Sqah4sSD6LoMsMA_Uqq_GWSvIanYo6BE42Lil7l8L0HKIOzlSViYINtBqHfvDDlbXSDt0t1Rn_kKCkxIU6iIKEwA6t8u2foJdn-res-rioTd3bNXH7dytAkMRbxZZR9SjK7V_lqgP2&sm=1" target="_blank">CLICK HERE FOR APPROVED VIDEOS</a>

                </div>
            </div>
            <div className="img">
                <img src="https://cdn.pixabay.com/photo/2018/08/10/08/06/constellation-3596293__340.jpg" alt=""/>
            </div>
        </div> 

    </div>
    </>
  )
}
