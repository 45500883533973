import React from 'react'
import './about.css';
import profile from '../images/profile.png';
import { useEffect } from 'react';
export default function About() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  return (
    <>
    <div class="about" style={{"display":"flex","flexDirection":"column"}}>
        <h1>WHO AM I ?</h1>
        <div class="aboutInner" style={{"display":"flex"}}>

            <div class="box">
                <h2>Satyanarayana Naik</h2>
                <p>
                    Being an Individual I should give some of my experiences, and  experiments, that is related to Astrology. The first thing is to say  that I am born in Mysore and belong to a Bharadwaja gotra hailing from a  Brahmin community. My father though not a professional astrologer, was  in general had good knowledge about it and was helpful to people.    Perhaps that gene I carried with me that took a turn in my life at an  appropriate Dasha. However, I landed up studying astrology. This study  turned into a passion for continuing education in Astrology field.  
                    <br/><br/>
                    More than 35 years have passed, I am still a student. It is because I  taught more than counseling and teaching needs constant upgrading of  knowledge. That helped me to understand the students and their  requirement. Glued to the authenticated texts experimenting and teaching  has become the way of life.   I have always preferred Interactive  sessions and encouraged students to participate. Participation and  practice are two important things to become perfect. 
                    <br/><br/>
                    There are no short  cut methods. The thorough study is the only method to learn astrology.  Sectional selective learning of short-term sessions Across the counter  will never be the route to perfection. I believe not only teaching but  firmly put my efforts in “Making Astrologers”.
                </p>
                
    
            </div>
            <div class="img">
                <img src={profile} alt=""/>
        </div>
        </div>
    </div>
    </>
  )
}
