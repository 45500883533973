import './App.css';
import { Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import About from './pages/About.js';
import Navbar from './pages/Navbar';
import Courses from './pages/Courses';
import Books from './pages/Books';
import Articles from './pages/Articles';
import Contact from './pages/Contact';
import Youtube from './pages/Youtube';
import Intro from './pages/Intro';
import Weekly from './pages/Weekly';
import Article from './pages/Article';
function App() {
  return (
    <>
    <Navbar/>
      <Routes basename="/" >
        <Route  path='/' element={<HomePage/>}/>
        <Route  path='/about' element={<About/>}/>
        <Route  path='/courses' element={<Courses/>}/>
        <Route  path='/books' element={<Books/>}/>
        <Route  path='/articles' element={<Articles/>}/>
        <Route  path='/contact' element={<Contact/>}/>
        <Route  path='/youtube' element={<Youtube/>}/>
        <Route  path='/intro' element={<Intro />}/>
        <Route  path='/weekly' element={<Weekly type="wp" head="Weekly Predictions"/>}/>
        <Route  path='/monthly' element={<Weekly type="mp" head="Monthly Predictions"/>}/>
        <Route  path='/yearly' element={<Weekly type="yp" head="Yearly Predictions"/>}/>
        <Route  path='/article/:id' element={<Article/>}/>
      </Routes >
      <div className="footer">
        <h3 style={{"textAlign":"center","color":"#ecdbf4",padding:"10px"}}>Copyright © 2024 fortunetelling.in - All Rights Reserved.</h3>
    </div>
    </>
  );
}
export default App;
