import React ,{useState,useEffect}from 'react'
import './weekly.css'
import apiServices from '../services/apiServices'
import vector from './vector.png'
import s1 from '../img/signs/s1.png'
export default function Weekly({type,head}) {
  const [data, setData] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const fetchEvents = async () => {
        try {
            const response = await apiServices.fetchAllPredictions();
            setData(response.data);
        } catch (error) {
        console.error('Error fetching Events:', error);
        }
    };
  useEffect(()=>{
    window.scrollTo(0,0);
    fetchEvents();
  },[]);
  var eventIds;
  if(data)
  {
    eventIds = Object.keys(data);

  }
  return (
    <div className='weekly'>
      <h1>{head}</h1>
      
      {data && eventIds.map((eventId) => {
        if(data[eventId].prediction==type)
        {
        return   <div className='box'>
        <div className='left'>
        <h1 >{data[eventId].blogtitle}</h1>
        <img src={data[eventId].blogthumbnail}/>
        </div>
        <div className='right'>
        <p style={{width:"100%", margin:"20px auto"}}>{data[eventId].blogdescription}</p>
        {data[eventId].blogcontent && data[eventId].blogcontent.map((item, id) => {
                                            if('heading' in item)
                                              return <h3 style={{width:"100%", margin:"20px auto",color:"white"}}><b>{item.heading}</b></h3>
                                            else if('subheading' in item)
                                              return <h3 style={{width:"100%", margin:"20px auto",color:"white"}}><b>{item.subheading}</b></h3>
                                            else if('paragraph' in item)
                                              return <p style={{width:"100%", margin:"20px auto"}}>{item.paragraph}</p>
                                            else if('image' in item)
                                              return <img className="img" src={item.image} alt="Description of the image"></img>
                                            else if('quote' in item)
                                              return <div style={{width:"100%", margin:"20px auto", backgroundColor:"lightblue", fontStyle:'italic', padding:"14px", borderLeft:"5px solid #065f7d"}}>"{item.quote}"</div>
                                            else if('note' in item)
                                              return <div style={{width:"100%", margin:"20px auto", fontStyle:'italic', padding:"14px", borderLeft:"5px solid #eb4438",color:"white"}}>Note: {item.note}</div>
        })}
        </div>
        </div>
    
        }

      })}

        
        

      
    </div>
  )
}
