import React from 'react'
import './books.css'
import { useRef,useEffect } from 'react';
export default function Books() {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
    let name;
    function show(e,img,title,author,link){
    let child=document.getElementById('sec1').children;
    let n=document.getElementById('sec1').childElementCount;
    for(let i=0;i<n;i++)
    {
        child[i].style.opacity=1;
        child[i].style.top=null;
        child[i].style.left=null;
    }
    let child2=document.getElementById('sec2').children;
    let n2=document.getElementById('sec2').childElementCount;
    for(let i=0;i<n2;i++)
    {
        child2[i].style.opacity=1;
        child2[i].style.top=null;
        child2[i].style.left=null;
    }
    document.getElementById('show').innerHTML='';
    if(e.target.className==='card card1')
    {
        e.target.style.top='-16vh';
        e.target.style.left='-50vw';
        e.target.style.opacity=0;
    }
    if(e.target.className==='card card2')
    {
        e.target.style.top='-30vh';
        e.target.style.left='-30vw';
        e.target.style.opacity=0;
    }
    setTimeout(()=>{
        let tag='https://'.concat(img);
        let tag2='https://'.concat(link);
        document.getElementById('show').innerHTML=`<img src='${tag}'/>
        <div class=" title">
                    <h2>${title}</h2>
        </div>
        <p >${author}</p>
    <a href="${tag2}" target="_blank">BUY NOW</a>
        `;
    },2000)
    
}
const handleClick=(e)=>{
    
}
  return (
    <>
      <div className="books" style={{paddingTop:"10vh"}}>
      <section id="featured-books" class="py-5 my-5">
        <h1 style={{textAlign:"center",color:'white'}}>
            OUR LIBRARY
        </h1>
      <div class="container" style={{paddingTop:"20px"}}>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header align-center">
            
            </div>
      
            <div class="product-list" data-aos="fade-up">
              <div class="row">
      
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/51dmAXYC9mL._SY445_SX342_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Roots-Naadi-Astrology-Comprehensive-Study/dp/8170821363/ref=sr_1_10?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-10">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Roots of Naadi Astrology</h3>
                      {/* <span>Armor Ramsey</span> */}
                      <div class="item-price">₹ 245.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/21KUhpZq8SL.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Celestial-Matrix-Naadi-Astrology-Prashna/dp/8170821029/ref=sr_1_11?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-11">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Celestial Matrix In Naadi Astrology</h3>
                      <div class="item-price">₹ 135.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/71DkdCKH3DL._SY466_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Revelation-Naadi-Jyotisha-Satyanarayana-Naik-ebook/dp/B0BDVXSGD1/ref=sr_1_13?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-13">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Revelation from Naadi Jyotisha</h3>
                      <div class="item-price">₹ 287.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/61oTW5S7lhL._SY466_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Naadi-Astrology-Transit-Satyanarayana-Naik-ebook/dp/B0B9424S9S/ref=sr_1_14?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-14">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Naadi Astrology - Transit Secrets</h3>
                      <div class="item-price">₹ 750.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/51-t55Gc+-L._SY445_SX342_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Prediction-Secrets-Astrology-Satyanarayan-Naik-ebook/dp/B0BC1S4KFC/ref=sr_1_17?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680280211&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-17">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Prediction Secrets – Naadi Astrology</h3>
                      <div class="item-price">₹ 449.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/5144ioAhaTL._SY445_SX342_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Remedies-Orthodox-Astrology-Satyanarayana-Naik/dp/8194306426/ref=sr_1_1?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-1">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Remedies Orthodox Astrology</h3>
                      <div class="item-price">₹ 325.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/51Q9xTp2uYL._SY445_SX342_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Prediction-Secrets-Astrology-Revised-Enlarged/dp/8170821738/ref=sr_1_2?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-2">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Prediction secrets naadi astrology</h3>
                      <div class="item-price">₹ 599.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/411eW-aFcTL._SY445_SX342_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Naadi-Astrology-Raos-System-Calculation/dp/8170822815/ref=sr_1_3?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-3">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Naadi Astrology (Rao's System of Naadi Astrology - Transits Results Expanded)</h3>
                      <div class="item-price">₹ 685.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/51lEh+2-z3L.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Vedic-Nadi-Secrets-Sastriya-Jyotisa/dp/8170822653/ref=sr_1_4?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-4">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Vedic Nadi Secrets in Sastriya Jyotisa: Nadimula in Sastriya Jyotisa</h3>
                      <div class="item-price">₹ 900.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/416XkoUU5yL._SY445_SX342_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Prashna-Hora-Horary-Astrology-Classical/dp/8170821045/ref=sr_1_5?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-5">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Prashna Hora</h3>
                      <div class="item-price">₹ 192.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/51kZtFHSu+L._SY466_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Astrology-Classically-Propitiation-Afflictions-Satyanarayana/dp/B09KP4WDRW/ref=sr_1_6?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-6">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Remedies Orthodox Astrology </h3>
                      <div class="item-price">₹ 325.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/41MZ0Qb9FSL._SY445_SX342_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/Providence-Scholarly-Exposition-Astrology-Satyanarayana/dp/B09MPPQTJW/ref=sr_1_7?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-7">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>Orbital Providence </h3>
                      <div class="item-price">₹ 200.00</div>
                    </figcaption>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="product-item">
                    <figure class="product-style">
                      <img src="https://m.media-amazon.com/images/I/51+vQoEs73L._SY445_SX342_.jpg" alt="Books" class="product-item"/>
                      <button type="button" class="add-to-cart" data-product-tile="add-to-cart"><a target="_blank" style={{color:"white",textDecoration:"none"}} href="https://www.amazon.in/MUHURTHA-SATYANARAYANA-NAIK/dp/8194922860/ref=sr_1_9?crid=2EPATFOE0LDQT&keywords=satyanarayana+naik+books&qid=1680274941&sprefix=satyanarayana+naik+books%2Caps%2C225&sr=8-9">
                        Buy Now
                        </a>
                        </button>
                    </figure>
                    <figcaption>
                      <h3>MUHURTHA </h3>
                      <div class="item-price">₹ 377.00</div>
                    </figcaption>
                  </div>
                </div>
      
      
              </div>
            </div>
      
      
          </div>
        </div>
      </div>
      </section>
          </div>
    </>
  )
}
