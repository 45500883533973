import React,{useEffect} from 'react'
import saturn from '../images/saturn.png'
import { Link } from 'react-router-dom'
// import './homepage.css'
export default function Navbar() {
    function fun()
        {
            document.getElementById('float').style.display="block";

        }
        function marker(e)
        {
            // let marker=document.getElementById("marker");
            // if(window.offsetWidth<=1000)
            // {
            //     marker.style.top=e.target.offsetTop+'px';
            // }
            // else
            // {
            //     marker.style.top=e.target.offsetTop+25+'px';
            // }
            // marker.style.width=e.target.offsetWidth+'px';
            // marker.style.left=e.target.offsetLeft-2+'px';
        }
        function fun1()
        {
            document.getElementById('float').style.display="none";
        }
        useEffect(()=>{
            // let marker=document.getElementById("marker");
            // marker.style.width=document.getElementById("first").offsetWidth+'px';
            // marker.style.top=document.getElementById("first").offsetTop+25+'px';

        },[])
  return (
    <div className="header">
        <nav className="nav" onmouseleave="fun1()">
            <div style={{"display":"flex"}}>
                <img src={saturn} id="planet" className="planet"  alt=""/>
                <div className="title">
                    <h1 >
                        <Link to="/" style={{"color":"black","textDecoration":"none"}}>
                            fortunetelling.in
                        </Link>
                    </h1>
                </div>

            </div>
            <ul>
                {/* <li style={{height:"1px",width:"0px",background:"black",position:"absolute",transition:"all 1s"}} id="marker"></li> */}
                <li>
                    <Link onClick={marker}  to="/" id="first" className="e2">HOME</Link>
                </li>
                <li>
                    <Link onClick={marker} to="/about" className="e2">ABOUT</Link>
                </li>
                <li id="f1">
                    <Link  className="e2"  onMouseOver={fun}  >INTRO-PRED</Link>
                    <div id="float" onMouseLeave={fun1}>
                        <Link to="/intro" className="f  e2" id="f11">ASTRO-INTRO </Link>
                        <Link to="/weekly" className="f  e2" id="f14">WEEKLY-PREDICTIONS</Link>
                        <Link to="/monthly" className="f  e2" id="f12">MONTLY-PREDICTIONS</Link>
                        <Link to="/yearly" className="f  e2" id="f13">YEARLY-PREDICTIONS</Link>
                        <Link to="/youtube" className="f  e2" id="f14">My-Channel</Link>
                    </div>
                </li>
                <li>
                    <Link onClick={marker}  to="/courses" className="e2">COURSES</Link>
                </li>
                <li>
                    <Link onClick={marker}  to="/books" className="e2">BOOKS</Link>
                </li>
                <li>
                    <Link onClick={marker} to="/articles" className="e2">ARTICLES</Link>
                </li>
                <li>
                    <Link onClick={marker}  to="/contact" className="e2">CONTACT</Link>
                </li>
            </ul>
        </nav>
    </div>
  )
}
