import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import apiServices from '../services/apiServices';
import { Link } from 'react-router-dom';
const Article = () => {
  const { id ,msg} = useParams();
  const [data,setData] = useState();
  const location = useLocation();
    const fetchBlog = async () => {
      try {
        const response = await apiServices.fetchEvent(id);
        // const response = await axios.get(`https://swayam-website-d9b3d-default-rtdb.asia-southeast1.firebasedatabase.app/blogs/${id}.json`);
        console.log(response.data)
        setData(response.data)
      } catch (error) {
          console.error('Error saving blog:', error);
      }
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
      };

  useEffect(() => {
    fetchBlog();
    window.scrollTo(0,0);
  }, [])

  return (
    <>

    <div className="articles">

    {data &&   <><div className="art" ><h1>{data.eventtitle}</h1>
                                        <p>By <Link to="/about">Satyanarayana naik</Link>{data.eventdate}</p>
                                        <img src={data.eventimageone} alt=""/>
                                        <p className="p">{data.eventDescription}
                                        <br/>
                                        </p>
                                        {data.blogContent && data.blogContent.map((item, id) => {
                                            if('heading' in item)
                                              return <h3 style={{width:"100%", margin:"20px auto",color:"white"}}><b>{item.heading}</b></h3>
                                            else if('subheading' in item)
                                              return <h5 style={{width:"100%", margin:"20px auto",color:"white"}}><b>{item.subheading}</b></h5>
                                            else if('paragraph' in item)
                                              return <p style={{width:"100%", margin:"20px auto"}}>{item.paragraph}</p>
                                            else if('image' in item)
                                              return <img className="img" src={item.image} alt="Description of the image"></img>
                                            else if('quote' in item)
                                              return <div style={{width:"100%", margin:"20px auto", backgroundColor:"lightblue", fontStyle:'italic', padding:"14px", borderLeft:"5px solid #065f7d"}}>"{item.quote}"</div>
                                            else if('note' in item)
                                              return <div style={{width:"100%", margin:"20px auto", fontStyle:'italic', padding:"14px", borderLeft:"5px solid #eb4438",color:"white"}}>Note: {item.note}</div>
        })}
                                    </div>
                                        </>

                                    
                                        
                                }
    </div>
    </>
  )
}

export default Article